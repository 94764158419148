import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormBuilder, FormGroup} from '@angular/forms';
import {ShowOfferPreference} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {SubscriptionTypeCatalogComponent} from '../../components/reference-book/subscription-type-catalog/subscription-type-catalog.component';
import {FullscreenLoaderService} from '../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';

export enum ShowOfferPreferenceCreatePopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

export interface ResourceObject {
    lastModified: string;
    name: string;
    size: number;
    url: string;
    resourceUri: string;
}

@Component({
    selector: 'app-show-offer-preference-create-popup',
    templateUrl: './show-offer-preference-create-popup.component.html',
    styleUrls: ['./show-offer-preference-create-popup.component.scss']
})
export class ShowOfferPreferenceCreatePopupComponent implements OnInit {

    public lang: FormControl = new FormControl(0);
    form: FormGroup;
    public loading = true;
    public waiting = false;
    public subscriptionTypeCatalogComponent = SubscriptionTypeCatalogComponent;
    public error: string | null = null;
    popupResult = ShowOfferPreferenceCreatePopupResult;
    private langName: Map<number, string> = new Map<number, string>([
        [0, 'ru'],
        [1, 'en']
    ]);

    constructor(
        private readonly dialogRef: MatDialogRef<ShowOfferPreferenceCreatePopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        private readonly fullscreenLoaderService: FullscreenLoaderService,
        @Inject(MAT_DIALOG_DATA) public readonly offerPreference: ShowOfferPreference
    ) {

        this.form = this.fb.group({});
        const main = this.fb.group({
            uuid: [null],
            name: [null],
            imageDesktop: [null],
            imageMobile: [null],
            displayed: [null],
            posterSort: [null],
            size: [null],
          });
        this.form.addControl('main', main);

        this.langName.forEach((langName) => {
            const lang = this.fb.group({
              title: [null],
              description: [null],
              note: [null],
              erid: [null],
              });
            this.form.addControl(langName, lang);
        });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        if (this.offerPreference) {
            this.form.patchValue({
                main: {
                    uuid: this.offerPreference.uuid,
                    name: this.offerPreference.name,
                    imageDesktop: this.offerPreference.data ? (this.offerPreference.data.imageDesktop ? this.offerPreference.data.imageDesktop : '') : '',
                    imageMobile: this.offerPreference.data ? (this.offerPreference.data.imageMobile ? this.offerPreference.data.imageMobile : '') : '',
                    displayed: this.offerPreference.data ? (this.offerPreference.data.displayed ? this.offerPreference.data.displayed : true) : true,
                    posterSort: this.offerPreference.data ? (this.offerPreference.data.posterSort ? this.offerPreference.data.posterSort : 0) : 0,
                    size: this.offerPreference.data ? (this.offerPreference.data.size ? this.offerPreference.data.size : 'small') : 'small',
                    }
                });
                this.langName.forEach((langName) => {
                    this.form.patchValue({
                        // @ts-ignore
                        [langName] : {
                            // @ts-ignore
                          title: this.offerPreference?.data?.text[langName]?.title,
                          description: this.offerPreference?.data?.text[langName]?.description,
                          note: this.offerPreference?.data?.text[langName]?.note,
                          erid: this.offerPreference?.data?.text[langName]?.erid,
                        }
                    });
                });
        }
        this.loading = false;
    }

    public close(result: ShowOfferPreferenceCreatePopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public async save(): Promise<void> {
        try {
            await this._save();
            this.close(ShowOfferPreferenceCreatePopupResult.SAVE);
        } catch (e) {

        }
    }

    public async _save() {

        let text = {};
        let data = {};
        this.langName.forEach((langName) => {
            let title: any[] = [];
            let description: any[] = [];
            let note: any[] = [];
            let erid: any[] = [];
            title = this.form.value[langName].title;
            description = this.form.value[langName].description;
            note = this.form.value[langName].note;
            erid = this.form.value[langName].erid;
            text = Object.assign({
              [langName]: {
                title,
                description,
                note,
                erid,
              }
            },
            text);
        });

        data = Object.assign({
          text,
          imageDesktop: this.form.value.main.imageDesktop ?? '',
          imageMobile: this.form.value.main.imageMobile ?? '',
          displayed: this.form.value.main.displayed ?? true,
          posterSort: this.form.value.main.posterSort ?? 0,
          size: this.form.value.main.size ?? 'small',
        }, data);

        const model: any = {
            data,
            name: this.form.value.main.name,
            uuid: this.form.value.main.uuid,
        };
        this.waiting = true;
        this.error = null;
        this.form.disable();
        try {
           if (this.offerPreference) {
                return await this.httpClient.put<ShowOfferPreference>(`/api/event-show-offer/update`, model).toPromise();
            } else {
                return await this.httpClient.post<ShowOfferPreference>(`/api/event-show-offer/create`, model).toPromise();
             }
        } catch (e) {
            //@ts-ignore
            this.error = e.error.message;
            this.form.enable();
            this.waiting = false;
            throw e;
        }
    }

    public onReferenceBookChange(tab: number): void {
        this.lang.setValue(tab);
    }

    public getLangName(index: number): string {
        return (this.langName.get(index)) as string;
    }

    public addImage(control: string, param: string): void {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', async (event) => {
            const files = (event.target as HTMLInputElement).files;
            const file = files && files[0];
            const formData: FormData = new FormData();
            formData.append('file', file as File);
            this.fullscreenLoaderService.open();
            try {
                const resourceObject = await this.httpClient.post<ResourceObject>(`/api/resource/object`, formData).toPromise();
                this.form.patchValue({
                    [control]: { [param]: resourceObject.resourceUri
                    }}, {emitEvent: false});
            } catch (e) {
            }
            this.fullscreenLoaderService.close();
        });
        input.click();
        input.remove();
    }

    public removeLink(control: string, param: string): void {
        this.form.patchValue({
            [control]: { [param]: null
            }});
    }

    public downloadResourceObject(control: string, param: string): void {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = this.form.controls[control].value[param];
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}
